<template>
  <b-card >
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('Activity Name')"
            label-for="h-activity-name"
          >
            <b-form-input
              id="h-activity-name"
              :placeholder="$t('Activity Name')"
              v-model="activityName"
               @blur="$v.activityName.$touch()"
            />
            <small v-if="!$v.activityName.required" class="form-text text-danger" >{{this.$t("This field is required")}}</small>
          </b-form-group>
        </b-col>

        <!-- <b-col cols="12">
          <b-form-group
            :label="$t('Activity Types')"
            label-for="h-activityTypes-name"
          >
            <v-select
              v-model="activityType"
              @blur="$v.activityType.$touch()"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :placeholder="$t('Activity Types')"
              label="name"
              value="activity_type_id"
              :options="options"
              color="primary"
            />
             <small v-if="!$v.activityType.required" class="form-text text-danger" >{{this.$t("This field is required")}}</small>
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
            <b-form-group
                :label="$t('Activity Time')"
                label-for="h-activity-time"
            >
            <b-form-select v-model="activityTime" :options="activityTimeList"></b-form-select>
          </b-form-group>
        </b-col>


         <b-col cols="12">
                  <b-form-group :label="$t('Activity Color')" label-for="h-activity-name">
                        <div class="text-left">
                          <v-swatches v-model="activityData.activity_color"></v-swatches>
                        </div>
                  </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4" class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            :to="{ name: 'activityList' }"
            variant="outline-secondary"
          > {{ $t("Cancel") }}
          </b-button>
           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
            @click="updateActivityData"
          >
            {{ $t("Update") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BBadge,
    BFormSelect
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import DataBase from "@/services/DataBase";
import vSelect from "vue-select";
import VSwatches from "vue-swatches";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css";
// import ContextMenuCloseOnClickVue from "@/views/extensions/context-menu/ContextMenuCloseOnClick.vue";
import {required} from "vuelidate/lib/validators";


export default {
  name: "activityEdit",
  components: {
    BCard,
    BBadge,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    VSwatches,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activity_id:"",
      color: "#ffffff",
      //codeHorizontal,
      activityType: [], // Seçilen verilerin yüklendiği liste
      activityName: null,
      options: [],
      result: false,
      activityData: {}, // veri tabanından gelen verilerin listelendiği liste
      companyBranchId: "",
      activity_id: "",
      activityTimeList: [15,30,45,60],
      activityTime: null
    };
  },
  computed:{
  },
  validations:
    {
        activityName:{
            required:required,
        },
        // activityType:{
        //     required:required,
        // }
    },
  methods: {
    async getActivityData() {
      await this.$database.StudioService.activity_get_by_id(
        this.companyBranchId,
        this.activity_id,
        1
      ).then((res) => {
        if (!res.is_success) return;
        this.activityData = res.result;
        // console.log(res)
        this.activityName=this.activityData.activity_name
        this.activityTime = this.activityData.activity_time
        this.activityType = this.activityData.activity_types
        .map(x=>{
          return {...x,color:"primary"}
         });
      });
    },
    updateActivityData()
    {
         if (this.$v.$invalid)
          return;
        let activityTypes = [];
        this.activityType.map(x=>activityTypes.push(x.activity_type_id));

        let _activityTypes = JSON.stringify(activityTypes);

        this.$database.StudioService.activity_update(this.activityData.activity_id, this.activityName, this.activityTime, this.companyBranchId,this.activityData.activity_color)
        .then((res) => {
          if (!res.IsSuccess) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Action failed!"),
                icon: "xIcon",
                variant: "danger",
              },
            });
            return;
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Activity Edited Succesful"),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.push('/activityList');
        });
         activityTypes = []; // diziyi temizle
    },
  },
  async created() {
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
    //Sayfa açıldığında ilk yapılacakların eklenecek olduğu ekran Biz bu sayfada activity tiplerini ekliyoruz
    this.activity_id = this.$route.params.activityID;
  //  await this.getActivityTypeList();
    await this.getActivityData();
  },
};
</script>
